<template>
  <div class="vouchers-container">
    <div
      v-for="(none, index) in internalValues"
      :key="index"
      class="apply-voucher-container"
    >
      <div class="apply-voucher">
        <v-text-field
          v-model="internalValues[index]"
          :color="colorBnn['color-bnn']"
          :readonly="validVoucher ? true : false"
          :error="
            $v.internalValues.$each[index].$error ||
              (!isVoucherAvailable(internalValues[index]) &&
                isVoucherSuccess) ||
              isError
          "
          :hide-details="true"
          :disabled="isVoucherAvailable(internalValues[index])"
          background-color="white"
          type="text"
          placeholder="กรอกคูปอง Trade-in"
          label="คูปอง Trade-in"
          ref="voucher_code"
          data-pptr="voucherCode"
          outlined
          inputmode="numeric"
          oninput="this.value=this.value.replace(/[^\d]+/g, '')"
          @input="resetVoucher"
        >
          <template v-slot:append>
            <v-icon
              v-if="isVoucherAvailable(internalValues[index])"
              color="green"
              size="20"
            >
              mdi-check
            </v-icon>
          </template>
        </v-text-field>

        <v-btn
          depressed
          class="btn-remove"
          @click="removeVoucher(index)"
        >
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </div>

      <div
        v-if="isVoucherAvailable(internalValues[index])"
        class="message-success title-medium"
      >
        <span>
          <v-icon color="#ccc">
            mdi-tag
          </v-icon>
          ส่วนลดจาก Voucher จำนวน
        </span>
        <span class="font-bold">
          {{ getVoucherAmount(internalValues[index]) | currency2 }}
        </span>
      </div>

      <div
        v-if="!isVoucherAvailable(internalValues[index])"
        class="message"
      >
        {{ getErrorMessage(internalValues[index], index) }}
      </div>
    </div>

    <div class="apply-voucher">
      <v-btn
        depressed
        :color="colorBnn['color-bnn-light']"
        :disabled="internalValues.length >= 3"
        @click="addVoucher"
      >
        เพิ่ม
      </v-btn>
      <v-btn
        depressed
        :dark="!isBnn"
        :color="colorBnn['color-bnn']"
        @click="applyVoucherCode"
      >
        ตรวจสอบ
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import isBnn from '@/config/isBnn'
import colorBnn from '@/config/color'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'ConfirmPaymentApplyVoucher',

  mixins: [validationMixin],

  props: {
    value: {
      type: Array,
      default: () => [''],
      required: true,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'กรอกคูปองส่วนลด',
    },
    isCanAddVoucher: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      // Import
      colorBnn,
      isBnn,

      form: {
        voucher_code: [],
      },

      internalValue: this.value,
      internalValues: this.value,
    }
  },

  validations() {
    return {
      internalValues: {
        $each: {
          required,
          valid: function(value) {
            return (
              this.internalValues.filter(v => v === value).length ===
              1
            )
          },
        },
      },
    }
  },

  computed: {
    ...mapState({
      voucher: state =>
        state.paymentType.voucher?.data?.voucher_result || {},
      isVoucherSuccess: state => state.paymentType.voucher?.isSuccess,
    }),

    validVoucher() {
      return !this.voucher.isError && this.voucher.code
    },

    msgError() {
      if (this.voucher.isError) {
        return this.voucher.errorMessage
      }
      return ''
    },
  },

  async mounted() {},

  methods: {
    isVoucherAvailable(code) {
      return this.voucher[code]?.is_available ?? false
    },

    getVoucherAmount(code) {
      return this.voucher[code]?.amount ?? 0
    },

    getErrorMessage(code, index) {
      if (this.$v.internalValues.$each[index].$error) {
        return !this.$v.internalValues.$each[index].required
          ? 'กรุณากรอกคูปอง'
          : !this.$v.internalValues.$each[index].valid
          ? 'ไม่สามารถใช้คูปองซ้ำได้'
          : ''
      } else {
        return this.voucher[code]?.message ?? this.errorMessages ?? ''
      }
    },

    async applyVoucherCode() {
      this.$v.$touch()

      if (this.$v.internalValues.$invalid) {
        return
      }

      const payload = {
        voucher_code: this.internalValues,
      }

      await this.$store.dispatch('paymentType/verifyVoucher', payload)
    },

    async clearVoucher() {
      this.internalValue = ''
      this.resetVoucher()
    },

    addVoucher() {
      if (this.internalValues.length < 3) {
        this.internalValues.push('')
      }
      this.resetVoucher()
    },

    removeVoucher(index) {
      this.internalValues.splice(index, 1)
      this.applyVoucherCode()
    },

    resetVoucher() {
      this.$v.$reset()
      this.$store.commit('paymentType/setVerifyVoucherReset')
    },
  },

  watch: {
    'voucher.code': {
      handler() {
        if (this.voucher.code && !this.voucher.isError) {
          this.internalValue = this.voucher.code
        } else {
          this.internalValue = ''
        }
      },
    },
    value(val) {
      this.internalValue = val
    },
    internalValue(val) {
      this.$emit('input', val)
    },
    internalValues(val) {
      if (!val.length) {
        val.push('')
      }

      this.$emit('input', val)
    },
  },
}
</script>

<style lang="stylus" scoped>
.vouchers-container
  display flex
  flex-direction column
  gap 16px
  // border-radius: 8px
  // padding 16px

.apply-voucher-container
  border-radius: 8px
  background-color: #f5f5f7
  display flex
  flex-direction column
  align-items center
  padding 16px
  gap 16px

.apply-voucher
  width 100%
  display flex
  flex-direction row
  flex-wrap nowrap
  align-items center
  justify-content flex-end
  gap 16px

form
  display flex
  flex-direction row
  align-items center

.message
  width 100%
  flex-basis 100%
  color red
  font-size 14px

.message-success
    width 100%

::v-deep .v-text-field--enclosed .v-input__prepend-inner {
    margin: auto 0
}

::v-deep .v-btn.btn-remove {
    padding: 0 !important
    min-width: 50px !important
    color: rgba(0,0,0, 0.45) !important
}
</style>
